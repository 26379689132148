.App {
  text-align: center;
  position: relative;
  font-family: 'Pridi', serif;
}

.bg {
  width: 1438px;
}

.start {
  width: 100px;
  height: 100px;
  left: 680px;
  top: 350px;
  position: absolute;
  z-index: 1000;
  transform: scale(1.2);
}
.coin1 {
  width: 100px;
  height: 100px;
  left: 680px;
  top: 60px;
  position: absolute;
  z-index: 1000;
  animation-name: to1;
  animation-duration: 24s;
}
.coin2 {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1000;
  left: 920px;
  top: 220px;
  animation-name: to2;
  animation-duration: 28s;
}
.coin3 {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1000;
  left: 920px;
  top: 465px;
  animation-name: to3;
  animation-duration: 32s;
}
.coin4 {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1000;
  left: 680px;
  top: 620px;
  animation-name: to4;
  animation-duration: 20s;
}
.coin5 {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1000;
  left: 440px;
  top: 470px;
  animation-name: to5;
  animation-duration: 30s;
}
.coin6 {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1000;
  left: 440px;
  top: 220px;
  animation-name: to6;
  animation-duration: 29s;
}
/*
Center left: 680px; top: 350px;
1 left: 680px; top: 80px;
2 left: 920px; top: 220px;
3 left: 920px; top: 465px;
4 left: 680px; top: 620px;
5 left: 440px; top: 465px;
6 left: 440px; top: 220px;
*/

/* Standard syntax */
@keyframes to1 {
  0% {
    left: 680px;
    top: 350px;
  } /*center*/
  2% {
    left: 920px;
    top: 220px;
  }
  4% {
    left: 920px;
    top: 465px;
  }
  6% {
    left: 440px;
    top: 465px;
  }
  8% {
    left: 920px;
    top: 220px;
  }
  10% {
    left: 920px;
    top: 220px;
  }
  12% {
    left: 920px;
    top: 465px;
  }
  14% {
    left: 920px;
    top: 465px;
  }
  16% {
    left: 680px;
    top: 620px;
  }
  18% {
    left: 920px;
    top: 465px;
  }
  20% {
    left: 440px;
    top: 465px;
  }
  22% {
    left: 440px;
    top: 220px;
  }
  24% {
    left: 680px;
    top: 80px;
  }
  26% {
    left: 680px;
    top: 620px;
  }
  28% {
    left: 680px;
    top: 80px;
  }
  30% {
    left: 920px;
    top: 465px;
  }
  32% {
    left: 920px;
    top: 465px;
  }
  34% {
    left: 680px;
    top: 620px;
  }
  36% {
    left: 440px;
    top: 220px;
  }
  38% {
    left: 680px;
    top: 80px;
  }
  40% {
    left: 920px;
    top: 465px;
  }
  42% {
    left: 680px;
    top: 620px;
  }
  44% {
    left: 440px;
    top: 465px;
  }
  46% {
    left: 440px;
    top: 220px;
  }
  48% {
    left: 680px;
    top: 620px;
  }
  50% {
    left: 440px;
    top: 220px;
  }
  52% {
    left: 680px;
    top: 80px;
  }
  54% {
    left: 920px;
    top: 220px;
  }
  56% {
    left: 440px;
    top: 220px;
  }
  58% {
    left: 920px;
    top: 220px;
  }
  60% {
    left: 920px;
    top: 220px;
  }
  62% {
    left: 680px;
    top: 80px;
  }
  64% {
    left: 680px;
    top: 620px;
  }
  66% {
    left: 920px;
    top: 465px;
  }
  68% {
    left: 920px;
    top: 465px;
  }
  70% {
    left: 440px;
    top: 465px;
  }
  72% {
    left: 440px;
    top: 465px;
  }
  74% {
    left: 440px;
    top: 465px;
  }
  76% {
    left: 920px;
    top: 220px;
  }
  78% {
    left: 920px;
    top: 465px;
  }
  80% {
    left: 680px;
    top: 80px;
  }
  82% {
    left: 920px;
    top: 465px;
  }
  84% {
    left: 440px;
    top: 465px;
  }
  86% {
    left: 920px;
    top: 465px;
  }
  88% {
    left: 920px;
    top: 220px;
  }
  90% {
    left: 680px;
    top: 80px;
  }
  92% {
    left: 440px;
    top: 465px;
  }
  94% {
    left: 680px;
    top: 620px;
  }
  96% {
    left: 440px;
    top: 220px;
  }
  98% {
    left: 680px;
    top: 620px;
  }
}
@keyframes to2 {
  0% {
    left: 680px;
    top: 350px;
  }
  2% {
    left: 440px;
    top: 220px;
  }
  4% {
    left: 440px;
    top: 220px;
  }
  6% {
    left: 440px;
    top: 220px;
  }
  8% {
    left: 680px;
    top: 620px;
  }
  10% {
    left: 920px;
    top: 220px;
  }
  12% {
    left: 440px;
    top: 465px;
  }
  14% {
    left: 920px;
    top: 220px;
  }
  16% {
    left: 440px;
    top: 220px;
  }
  18% {
    left: 440px;
    top: 465px;
  }
  20% {
    left: 920px;
    top: 465px;
  }
  22% {
    left: 680px;
    top: 620px;
  }
  24% {
    left: 680px;
    top: 80px;
  }
  26% {
    left: 920px;
    top: 220px;
  }
  28% {
    left: 440px;
    top: 220px;
  }
  30% {
    left: 920px;
    top: 465px;
  }
  32% {
    left: 920px;
    top: 465px;
  }
  34% {
    left: 680px;
    top: 620px;
  }
  36% {
    left: 680px;
    top: 620px;
  }
  38% {
    left: 680px;
    top: 80px;
  }
  40% {
    left: 920px;
    top: 220px;
  }
  42% {
    left: 440px;
    top: 465px;
  }
  44% {
    left: 440px;
    top: 465px;
  }
  46% {
    left: 920px;
    top: 220px;
  }
  48% {
    left: 440px;
    top: 220px;
  }
  50% {
    left: 920px;
    top: 220px;
  }
  52% {
    left: 920px;
    top: 220px;
  }
  54% {
    left: 440px;
    top: 465px;
  }
  56% {
    left: 920px;
    top: 465px;
  }
  58% {
    left: 440px;
    top: 220px;
  }
  60% {
    left: 440px;
    top: 465px;
  }
  62% {
    left: 680px;
    top: 620px;
  }
  64% {
    left: 440px;
    top: 220px;
  }
  66% {
    left: 920px;
    top: 220px;
  }
  68% {
    left: 680px;
    top: 80px;
  }
  70% {
    left: 680px;
    top: 80px;
  }
  72% {
    left: 920px;
    top: 220px;
  }
  74% {
    left: 920px;
    top: 465px;
  }
  76% {
    left: 920px;
    top: 220px;
  }
  78% {
    left: 680px;
    top: 80px;
  }
  80% {
    left: 920px;
    top: 465px;
  }
  82% {
    left: 920px;
    top: 220px;
  }
  84% {
    left: 920px;
    top: 220px;
  }
  86% {
    left: 920px;
    top: 465px;
  }
  88% {
    left: 680px;
    top: 80px;
  }
  90% {
    left: 680px;
    top: 620px;
  }
  92% {
    left: 920px;
    top: 220px;
  }
  94% {
    left: 920px;
    top: 465px;
  }
  96% {
    left: 440px;
    top: 220px;
  }
  98% {
    left: 440px;
    top: 220px;
  }
}

@keyframes to3 {
  0% {
    left: 680px;
    top: 350px;
  }
  2% {
    left: 920px;
    top: 465px;
  }
  4% {
    left: 440px;
    top: 220px;
  }
  6% {
    left: 680px;
    top: 620px;
  }
  8% {
    left: 680px;
    top: 80px;
  }
  10% {
    left: 920px;
    top: 465px;
  }
  12% {
    left: 920px;
    top: 465px;
  }
  14% {
    left: 680px;
    top: 620px;
  }
  16% {
    left: 920px;
    top: 465px;
  }
  18% {
    left: 920px;
    top: 465px;
  }
  20% {
    left: 920px;
    top: 220px;
  }
  22% {
    left: 440px;
    top: 220px;
  }
  24% {
    left: 440px;
    top: 220px;
  }
  26% {
    left: 920px;
    top: 465px;
  }
  28% {
    left: 680px;
    top: 80px;
  }
  30% {
    left: 680px;
    top: 620px;
  }
  32% {
    left: 680px;
    top: 620px;
  }
  34% {
    left: 680px;
    top: 620px;
  }
  36% {
    left: 920px;
    top: 465px;
  }
  38% {
    left: 440px;
    top: 465px;
  }
  40% {
    left: 680px;
    top: 620px;
  }
  42% {
    left: 440px;
    top: 465px;
  }
  44% {
    left: 920px;
    top: 220px;
  }
  46% {
    left: 440px;
    top: 465px;
  }
  48% {
    left: 440px;
    top: 465px;
  }
  50% {
    left: 680px;
    top: 80px;
  }
  52% {
    left: 440px;
    top: 465px;
  }
  54% {
    left: 440px;
    top: 220px;
  }
  56% {
    left: 680px;
    top: 620px;
  }
  58% {
    left: 680px;
    top: 620px;
  }
  60% {
    left: 920px;
    top: 465px;
  }
  62% {
    left: 920px;
    top: 465px;
  }
  64% {
    left: 920px;
    top: 220px;
  }
  66% {
    left: 920px;
    top: 465px;
  }
  68% {
    left: 440px;
    top: 465px;
  }
  70% {
    left: 920px;
    top: 220px;
  }
  72% {
    left: 440px;
    top: 465px;
  }
  74% {
    left: 920px;
    top: 220px;
  }
  76% {
    left: 440px;
    top: 465px;
  }
  78% {
    left: 680px;
    top: 80px;
  }
  80% {
    left: 920px;
    top: 465px;
  }
  82% {
    left: 680px;
    top: 80px;
  }
  84% {
    left: 920px;
    top: 465px;
  }
  86% {
    left: 920px;
    top: 465px;
  }
  88% {
    left: 680px;
    top: 620px;
  }
  90% {
    left: 680px;
    top: 620px;
  }
  92% {
    left: 920px;
    top: 465px;
  }
  94% {
    left: 440px;
    top: 465px;
  }
  96% {
    left: 440px;
    top: 465px;
  }
  98% {
    left: 920px;
    top: 220px;
  }
}
@keyframes to4 {
  0% {
    left: 680px;
    top: 350px;
  }
  2% {
    left: 440px;
    top: 220px;
  }
  4% {
    left: 920px;
    top: 465px;
  }
  6% {
    left: 920px;
    top: 465px;
  }
  8% {
    left: 920px;
    top: 220px;
  }
  10% {
    left: 920px;
    top: 220px;
  }
  12% {
    left: 440px;
    top: 465px;
  }
  14% {
    left: 680px;
    top: 620px;
  }
  16% {
    left: 920px;
    top: 220px;
  }
  18% {
    left: 680px;
    top: 80px;
  }
  20% {
    left: 440px;
    top: 465px;
  }
  22% {
    left: 680px;
    top: 80px;
  }
  24% {
    left: 920px;
    top: 220px;
  }
  26% {
    left: 680px;
    top: 80px;
  }
  28% {
    left: 920px;
    top: 220px;
  }
  30% {
    left: 440px;
    top: 465px;
  }
  32% {
    left: 920px;
    top: 220px;
  }
  34% {
    left: 440px;
    top: 465px;
  }
  36% {
    left: 920px;
    top: 465px;
  }
  38% {
    left: 440px;
    top: 465px;
  }
  40% {
    left: 440px;
    top: 220px;
  }
  42% {
    left: 440px;
    top: 220px;
  }
  44% {
    left: 920px;
    top: 220px;
  }
  46% {
    left: 440px;
    top: 220px;
  }
  48% {
    left: 920px;
    top: 465px;
  }
  50% {
    left: 920px;
    top: 220px;
  }
  52% {
    left: 920px;
    top: 220px;
  }
  54% {
    left: 680px;
    top: 80px;
  }
  56% {
    left: 920px;
    top: 465px;
  }
  58% {
    left: 920px;
    top: 220px;
  }
  60% {
    left: 440px;
    top: 465px;
  }
  62% {
    left: 680px;
    top: 80px;
  }
  64% {
    left: 920px;
    top: 220px;
  }
  66% {
    left: 920px;
    top: 465px;
  }
  68% {
    left: 680px;
    top: 80px;
  }
  70% {
    left: 440px;
    top: 220px;
  }
  72% {
    left: 440px;
    top: 220px;
  }
  74% {
    left: 920px;
    top: 220px;
  }
  76% {
    left: 680px;
    top: 620px;
  }
  78% {
    left: 920px;
    top: 220px;
  }
  80% {
    left: 920px;
    top: 220px;
  }
  82% {
    left: 920px;
    top: 220px;
  }
  84% {
    left: 920px;
    top: 220px;
  }
  86% {
    left: 680px;
    top: 80px;
  }
  88% {
    left: 440px;
    top: 465px;
  }
  90% {
    left: 440px;
    top: 220px;
  }
  92% {
    left: 680px;
    top: 80px;
  }
  94% {
    left: 680px;
    top: 620px;
  }
  96% {
    left: 440px;
    top: 220px;
  }
  98% {
    left: 920px;
    top: 465px;
  }
}
@keyframes to5 {
  0% {
    left: 680px;
    top: 350px;
  }
  2% {
    left: 440px;
    top: 220px;
  }
  4% {
    left: 680px;
    top: 80px;
  }
  6% {
    left: 440px;
    top: 465px;
  }
  8% {
    left: 680px;
    top: 80px;
  }
  10% {
    left: 440px;
    top: 220px;
  }
  12% {
    left: 440px;
    top: 220px;
  }
  14% {
    left: 440px;
    top: 465px;
  }
  16% {
    left: 440px;
    top: 220px;
  }
  18% {
    left: 920px;
    top: 220px;
  }
  20% {
    left: 440px;
    top: 465px;
  }
  22% {
    left: 920px;
    top: 465px;
  }
  24% {
    left: 920px;
    top: 465px;
  }
  26% {
    left: 680px;
    top: 620px;
  }
  28% {
    left: 440px;
    top: 465px;
  }
  30% {
    left: 440px;
    top: 220px;
  }
  32% {
    left: 440px;
    top: 220px;
  }
  34% {
    left: 440px;
    top: 465px;
  }
  36% {
    left: 920px;
    top: 220px;
  }
  38% {
    left: 680px;
    top: 80px;
  }
  40% {
    left: 920px;
    top: 220px;
  }
  42% {
    left: 440px;
    top: 220px;
  }
  44% {
    left: 440px;
    top: 220px;
  }
  46% {
    left: 920px;
    top: 220px;
  }
  48% {
    left: 440px;
    top: 465px;
  }
  50% {
    left: 440px;
    top: 220px;
  }
  52% {
    left: 920px;
    top: 220px;
  }
  54% {
    left: 680px;
    top: 620px;
  }
  56% {
    left: 440px;
    top: 220px;
  }
  58% {
    left: 680px;
    top: 80px;
  }
  60% {
    left: 680px;
    top: 80px;
  }
  62% {
    left: 440px;
    top: 220px;
  }
  64% {
    left: 920px;
    top: 220px;
  }
  66% {
    left: 920px;
    top: 465px;
  }
  68% {
    left: 920px;
    top: 465px;
  }
  70% {
    left: 920px;
    top: 220px;
  }
  72% {
    left: 920px;
    top: 220px;
  }
  74% {
    left: 680px;
    top: 620px;
  }
  76% {
    left: 680px;
    top: 620px;
  }
  78% {
    left: 920px;
    top: 465px;
  }
  80% {
    left: 440px;
    top: 465px;
  }
  82% {
    left: 680px;
    top: 620px;
  }
  84% {
    left: 440px;
    top: 465px;
  }
  86% {
    left: 920px;
    top: 220px;
  }
  88% {
    left: 680px;
    top: 620px;
  }
  90% {
    left: 440px;
    top: 220px;
  }
  92% {
    left: 680px;
    top: 620px;
  }
  94% {
    left: 680px;
    top: 620px;
  }
  96% {
    left: 440px;
    top: 465px;
  }
  98% {
    left: 680px;
    top: 620px;
  }
}
@keyframes to6 {
  0% {
    left: 680px;
    top: 350px;
  }
  2% {
    left: 680px;
    top: 620px;
  }
  4% {
    left: 920px;
    top: 465px;
  }
  6% {
    left: 680px;
    top: 80px;
  }
  8% {
    left: 680px;
    top: 620px;
  }
  10% {
    left: 920px;
    top: 220px;
  }
  12% {
    left: 920px;
    top: 220px;
  }
  14% {
    left: 680px;
    top: 80px;
  }
  16% {
    left: 680px;
    top: 80px;
  }
  18% {
    left: 440px;
    top: 220px;
  }
  20% {
    left: 920px;
    top: 465px;
  }
  22% {
    left: 920px;
    top: 465px;
  }
  24% {
    left: 680px;
    top: 620px;
  }
  26% {
    left: 680px;
    top: 80px;
  }
  28% {
    left: 440px;
    top: 465px;
  }
  30% {
    left: 440px;
    top: 465px;
  }
  32% {
    left: 680px;
    top: 80px;
  }
  34% {
    left: 440px;
    top: 465px;
  }
  36% {
    left: 440px;
    top: 220px;
  }
  38% {
    left: 680px;
    top: 620px;
  }
  40% {
    left: 920px;
    top: 465px;
  }
  42% {
    left: 440px;
    top: 220px;
  }
  44% {
    left: 440px;
    top: 220px;
  }
  46% {
    left: 920px;
    top: 465px;
  }
  48% {
    left: 440px;
    top: 465px;
  }
  50% {
    left: 440px;
    top: 465px;
  }
  52% {
    left: 440px;
    top: 220px;
  }
  54% {
    left: 680px;
    top: 80px;
  }
  56% {
    left: 680px;
    top: 620px;
  }
  58% {
    left: 920px;
    top: 220px;
  }
  60% {
    left: 680px;
    top: 620px;
  }
  62% {
    left: 440px;
    top: 220px;
  }
  64% {
    left: 920px;
    top: 220px;
  }
  66% {
    left: 440px;
    top: 465px;
  }
  68% {
    left: 440px;
    top: 465px;
  }
  70% {
    left: 920px;
    top: 220px;
  }
  72% {
    left: 920px;
    top: 465px;
  }
  74% {
    left: 440px;
    top: 465px;
  }
  76% {
    left: 920px;
    top: 220px;
  }
  78% {
    left: 920px;
    top: 220px;
  }
  80% {
    left: 920px;
    top: 220px;
  }
  82% {
    left: 440px;
    top: 465px;
  }
  84% {
    left: 680px;
    top: 620px;
  }
  86% {
    left: 680px;
    top: 620px;
  }
  88% {
    left: 920px;
    top: 220px;
  }
  90% {
    left: 680px;
    top: 620px;
  }
  92% {
    left: 440px;
    top: 465px;
  }
  94% {
    left: 920px;
    top: 220px;
  }
  96% {
    left: 920px;
    top: 465px;
  }
  98% {
    left: 920px;
    top: 220px;
  }
}
@keyframes earth {
  0% {
    left: 680px;
    top: 300px;
  }
  2% {
    left: 810px;
    top: 234px;
  }
  4% {
    left: 695px;
    top: 150px;
  }
  6% {
    left: 835px;
    top: 448px;
  }
  8% {
    left: 608px;
    top: 284px;
  }
  10% {
    left: 514px;
    top: 59px;
  }
  12% {
    left: 595px;
    top: 128px;
  }
  14% {
    left: 691px;
    top: 356px;
  }
  16% {
    left: 802px;
    top: 366px;
  }
  18% {
    left: 654px;
    top: 98px;
  }
  20% {
    left: 487px;
    top: 215px;
  }
  22% {
    left: 599px;
    top: 351px;
  }
  24% {
    left: 838px;
    top: 357px;
  }
  26% {
    left: 652px;
    top: 178px;
  }
  28% {
    left: 784px;
    top: 144px;
  }
  30% {
    left: 765px;
    top: 373px;
  }
  32% {
    left: 451px;
    top: 404px;
  }
  34% {
    left: 795px;
    top: 124px;
  }
  36% {
    left: 585px;
    top: 147px;
  }
  38% {
    left: 751px;
    top: 437px;
  }
  40% {
    left: 687px;
    top: 365px;
  }
  42% {
    left: 793px;
    top: 83px;
  }
  44% {
    left: 630px;
    top: 133px;
  }
  46% {
    left: 826px;
    top: 148px;
  }
  48% {
    left: 463px;
    top: 205px;
  }
  50% {
    left: 762px;
    top: 365px;
  }
  52% {
    left: 738px;
    top: 318px;
  }
  54% {
    left: 768px;
    top: 205px;
  }
  56% {
    left: 584px;
    top: 85px;
  }
  58% {
    left: 612px;
    top: 309px;
  }
  60% {
    left: 555px;
    top: 265px;
  }
  62% {
    left: 778px;
    top: 324px;
  }
  64% {
    left: 783px;
    top: 149px;
  }
  66% {
    left: 544px;
    top: 213px;
  }
  68% {
    left: 561px;
    top: 68px;
  }
  70% {
    left: 719px;
    top: 112px;
  }
  72% {
    left: 570px;
    top: 247px;
  }
  74% {
    left: 837px;
    top: 100px;
  }
  76% {
    left: 832px;
    top: 268px;
  }
  78% {
    left: 676px;
    top: 148px;
  }
  80% {
    left: 744px;
    top: 64px;
  }
  82% {
    left: 548px;
    top: 145px;
  }
  84% {
    left: 521px;
    top: 219px;
  }
  86% {
    left: 451px;
    top: 348px;
  }
  88% {
    left: 576px;
    top: 250px;
  }
  90% {
    left: 604px;
    top: 142px;
  }
  92% {
    left: 616px;
    top: 114px;
  }
  94% {
    left: 460px;
    top: 204px;
  }
  96% {
    left: 505px;
    top: 102px;
  }
  98% {
    left: 624px;
    top: 313px;
  }
  100% {
    left: 495px;
    top: 318px;
  }
}

.show-team {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  z-index: 1000;
  font-size: 55px;
  transition: all 0.4s ease;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 15px 70px;
  border-radius: 10px;
  font-family: 'Pridi', serif;
  font-weight: 400;
}

.show-team.show {
  opacity: 1;
}

.background-board {
  position: absolute;
  background-image: url('./paper_board.png');
  background-size: contain;
  width: 600px;
  height: 300px;
  background-position-y: -50px;
  background-repeat: no-repeat;
  top: 240px;
  left: 430px;
  padding-top: 120px;
  text-align: center;
}

.caster {
  position: absolute;
  transform: scaleX(-1);
  width: 29%;
  top: 73px;
  left: 4%;
}

#fire {
  position: absolute;
  top: -215px;
  left: 0;
  width: 1412px;
  height: 1200px;
}